@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
@font-face {
  font-family: grafitti;
  src: url(/src/font/grafitti.ttf);
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
}
.image,
.section {
  height: 100vh;
  position: relative;
}

.outerDiv {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerH1 {
  font-size: 50px;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.project {
  cursor: pointer;
  transition: ease-in-out;
}

.project:hover {
  color: rgb(122, 86, 86);
}

.backdrop {
  width: 40vh;
  height: 8vh;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  text-align: center;
}

.head {
  background-size: 100vh;
  background-repeat: no-repeat;
  background-position: bottom 30% right 50%;
  font-family: 'Lato';
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.5rem;
  text-align: center;
}

.brick {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: grafitti;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.5rem;
}

.about-text {
  text-align: center;
  padding: 5px;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-family: 'Lato';
}

.modalBackdrop {
  background: rgba(0, 0, 0, 0.85);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modalContainer {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 70vh;
  box-shadow: 24;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.modalTitle {
  font-size: 2rem;
}

.modalImage {
  height: 30vh;
  width: auto;
}

.modalVisits {
  display: flex;
  justify-content: space-around;
}

.close {
  position: absolute;
  top: 10%;
  right: 15%;
}

.close:hover {
  cursor: pointer;
}

button {
  background: var(--primary);
  border: 0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  padding: 10px 15px;
}

.contact-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.icon {
  transform: scale(5);
  color: rgba(43, 89, 113, 0.7);
}

.icon:hover {
  transition: 1s linear;
  color: rgba(43, 89, 113, 1);
  transform: scale(6.5);
}

hr {
  color: #000;
  background-color: #000;
  width: 70%;
}

@media screen and (min-width: 675px) {
  .brick {
    align-items: center;
  }
  .head {
    background-size: cover;
    background-position: bottom 30% right;
  }
}

@media screen and (min-width: 480px) {
  .about-text {
    font-size: 1.7rem;
    padding: 20px;
  }
  .contact-footer {
    flex-direction: row;
  }
}
